<template>
  <div id="SalesScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9">
          <sales-list></sales-list>
        </v-col>
        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="white">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  dense
                  outlined
                  label="No. Penjualan"
                  v-model="noSales"
                  clearable
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Nama Operator"
                  v-model="operatorName"
                  clearable
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="getSalesBySearch"
                :loading="loading"
                :disabled="loadingStatus"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SalesList from "@/components/Widget/mitra/SalesList";
import globalMixin from "@/mixins/globalMixin.js";
// import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SalesList
  },

  mixins: [globalMixin],

  data() {
    return {
      valid: true,
      loading: false,
      fromDate: "",
      toDate: "",
      menuFromDate: false,
      menuToDate: false,
      noSales: "",
      operatorName: ""
    };
  },

  mounted() {
    // if (this.getSales && this.getSales.length > 0) return; //  this is for stopping call API loop
    // this.getAll();
    this.$store.dispatch("sales/getAll");
  },

  computed: {
    // ...mapGetters("sales", ["getSales"])
    loadingStatus() {
      return this.$store.getters.getLoading;
    }
  },

  methods: {
    //   // ...mapActions("sales", ["getAll"]),

    //   // ...mapActions("sales", ["getBySearch"]),
    //   // async getData() {
    //   //   this.$root.$loaderModal.start("Loading...");

    //   //   const params = {
    //   //     transaction_date: this.date,
    //   //     method: "",
    //   //     noSales: this.noSales,
    //   //     transaction_number: this.noSales
    //   //   };

    //   //   this.getBySearch(params);
    //   //   this.$root.$loaderModal.hide();
    //   // }

    getSalesBySearch() {
      this.loading = true;

      let params = {
        start_date: this.fromDate,
        end_date: this.toDate,
        transaction_number: this.noSales,
        operator_name: this.operatorName
      };

      this.$store
        .dispatch("sales/getBySearch", params)
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
