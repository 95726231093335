<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Penjualan</h5>
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="refreshData">mdi-refresh</v-icon>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="salesData"
            :loading="loadingStatus"
            loading-text="Sedang Menyiapkan Data..."
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td class="detailLink" @click="getSalesById(props.item.id)">
                  {{ props.item.transaction_numb }}
                </td>
                <td>{{ props.item.total_items }}</td>
                <td>
                  {{ formatRupiah(props.item.grand_total) }}
                </td>
                <td>
                  <v-chip
                    :color="getColor(props.item.transaction_method_id)"
                    dark
                    >{{ props.item.method }}</v-chip
                  >
                </td>
                <td>{{ props.item.transaction_date }}</td>
                <td>{{ props.item.customer_name }}</td>
                <td>{{ props.item.operator_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor Struk: {{ salesDataDetail.transaction_numb }}</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="printReportSales"
            :loading="loading"
            :disabled="loadingStatus"
            >Cetak Struk</v-btn
          >
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Customer
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ salesDataDetail.customer_name }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Nama Operator
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ salesDataDetail.operator_name }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Alamat
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ salesDataDetail.address }}
                </div>
              </v-col>
            </v-row>

            <product-table :products="salesDataDetail.detail"></product-table>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Total :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatAmount(totalItem) }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Jumlah Pembayaran :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatRupiah(totalPrice) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import ProductTable from "@/components/Table/ProductTable";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

export default {
  // props: ["listSales"],
  mixins: [globalMixin],

  components: {
    ProductTable
  },

  data() {
    return {
      dialog: false,
      loading: false,
      headers: [
        { text: "Nomor Struk", sortable: false },
        { text: "Jumlah Produk", sortable: false },
        { text: "Total", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Tanggal Penjualan", sortable: false },
        { text: "Customer", sortable: false },
        { text: "Nama Operator", sortable: false }
      ],
      totalItem: 0,
      totalPrice: 0,
      methodColor: ["disabled ", "#432575", "#00AA13", "#E62B1E", "primary"] //cash, ovo, gopay, linkAja, transferBank
    };
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    salesData() {
      return this.$store.getters["sales/getSales"];
    },
    salesDataDetail() {
      return this.$store.getters["sales/getSalesDetail"];
    }
  },

  methods: {
    getSalesById(id) {
      this.$root.$loaderModal.start("Loading...");

      let params = {
        id: id
      };

      this.$store
        .dispatch("sales/getById", params)
        .then(() => {
          this.dialog = true;
          let resp = this.salesDataDetail.detail;
          let totalItem = 0;
          let totalPrice = 0;

          resp.forEach(element => {
            totalItem = totalItem + parseInt(element["quantity"], 10);
            totalPrice = totalPrice + parseInt(element["subtotal"], 10);
          });

          this.totalItem = totalItem;
          this.totalPrice = totalPrice;
        })
        .catch(() => {
          this.$tostini({
            message: ERROR_SOMETHING_WRONG,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    refreshData() {
      this.$store.dispatch("sales/getAll");
    },
    printReportSales() {
      this.loading = true;

      this.$store
        .dispatch(
          "sales/printReportSales",
          this.salesDataDetail?.transaction_numb
        )
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getColor(flag) {
      return this.methodColor[flag - 1];
    }
  }
};
</script>
